<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <div>
          <h5 class="mb-4">
            <strong>Background Utilities</strong>
          </h5>
          <div class="mb-5">
            <div class="row">
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-primary rounded p-3 text-white">
                  .bg-primary
                  <br />
                  .text-primary
                  <br />
                  mixin: $primary
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-default rounded p-3 text-white">
                  .bg-default
                  <br />
                  .text-default
                  <br />
                  mixin: $default
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-secondary rounded p-3 text-white">
                  .bg-secondary
                  <br />
                  .text-secondary
                  <br />
                  mixin: $secondary
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-success rounded p-3 text-white">
                  .bg-success
                  <br />
                  .text-success
                  <br />
                  mixin: $success
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-danger rounded p-3 text-white">
                  .bg-danger
                  <br />
                  .text-danger
                  <br />
                  mixin: $danger
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-warning rounded p-3 text-white">
                  .bg-warning
                  <br />
                  .text-warning
                  <br />
                  mixin: $warning
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-info rounded p-3 text-white">
                  .bg-info
                  <br />
                  .text-info
                  <br />
                  mixin: $info
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-light rounded p-3">
                  .bg-light
                  <br />
                  .text-light
                  <br />
                  mixin: $light
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-dark rounded p-3 text-white">
                  .bg-dark
                  <br />
                  .text-dark
                  <br />
                  mixin: $dark
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-white rounded p-3">
                  .bg-white
                  <br />
                  .text-white
                  <br />
                  mixin: $white
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-blue rounded p-3 text-white">
                  .bg-blue
                  <br />
                  .text-blue
                  <br />
                  mixin: $blue
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-blue-light rounded p-3 text-white">
                  .bg-blue-light
                  <br />
                  .text-blue-light
                  <br />
                  mixin: $blue-light
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-red rounded p-3 text-white">
                  .bg-red
                  <br />
                  .text-red
                  <br />
                  mixin: $red
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-yellow rounded p-3">
                  .bg-yellow
                  <br />
                  .text-yellow
                  <br />
                  mixin: $yellow
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-orange rounded p-3 text-white">
                  .bg-orange
                  <br />
                  .text-orange
                  <br />
                  mixin: $orange
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-gray-1 rounded p-3">
                  .bg-gray-
                  <br />
                  .text-gray
                  <br />
                  mixin: $gray
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-gray-2 rounded p-3">
                  .bg-gray-2
                  <br />
                  .text-gray-2
                  <br />
                  mixin: $gray-2
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-gray-3 rounded p-3">
                  .bg-gray-3
                  <br />
                  .text-gray-3
                  <br />
                  mixin: $gray-3
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-gray-4 rounded p-3 text-white">
                  .bg-gray-4
                  <br />
                  .text-gray-4
                  <br />
                  mixin: $gray-4
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-gray-5 rounded p-3 text-white">
                  .bg-gray-5
                  <br />
                  .text-gray-5
                  <br />
                  mixin: $gray-5
                </div>
              </div>
              <div class="col-lg-4">
                <div class="height-100 font-size-12 mb-3 bg-gray-6 rounded p-3 text-white">
                  .bg-gray-6
                  <br />
                  .text-gray-6
                  <br />
                  mixin: $gray-6
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div>
          <h5 class="mb-4">
            <strong>Text Utilities</strong>
          </h5>
          <div class="mb-5">
            <table class="table table-hover">
              <colgroup>
                <col class="col-xs-4" />
                <col class="col-xs-8" />
              </colgroup>
              <thead>
                <tr>
                  <th class="text-nowrap">Class</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-nowrap">
                    <code>.text-default</code>
                  </td>
                  <td>
                    <span class="text-default">Set Default color to element</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">
                    <code>.text-primary</code>
                  </td>
                  <td>
                    <span class="text-primary">Set Primary color to element</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">
                    <code>.text-secondary</code>
                  </td>
                  <td>
                    <span class="text-secondary">Set Secondary color to element</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">
                    <code>.text-success</code>
                  </td>
                  <td>
                    <span class="text-success">Set Success color to element</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">
                    <code>.text-info</code>
                  </td>
                  <td>
                    <span class="text-info">Set Info color to element</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">
                    <code>.text-warning</code>
                  </td>
                  <td>
                    <span class="text-warning">Set Warning color to element</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">
                    <code>.text-danger</code>
                  </td>
                  <td>
                    <span class="text-danger">Set Danger color to element</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">
                    <code>.text-light</code>
                  </td>
                  <td>
                    <span class="text-light">Set Light color to element</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">
                    <code>.text-dark</code>
                  </td>
                  <td>
                    <span class="text-dark">Set Black color to element</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
